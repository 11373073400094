import React from "react";
import aboutPic from "../../assets/about-pic.png";
import experienceIcon from "../../assets/experience.png";
import educationIcon from "../../assets/education.png";
import arrowIcon from "../../assets/arrow.png";

const About = () => {
  return (
    <section id="about">
      <p className="section__text__p1">Get To Know More</p>
      <h1 className="title">About Me</h1>
      <div className="section-container">
        <div className="section__pic-container">
          <img src={aboutPic} alt="Aayush working" className="about-pic" />
        </div>
        <div className="about-details-container">
          <div className="about-containers">
            <div className="details-container">
              <img src={experienceIcon} alt="Experience" className="icon" />
              <h3>Experience</h3>
              <p>
                Fresher <br />
                Frontend Development
              </p>
            </div>
            <div className="details-container">
              <img src={educationIcon} alt="Education" className="icon" />
              <h3>Education</h3>
              <p>
                Bachelors in Information Management
                <br />
              </p>
            </div>
          </div>
          <div className="text-container">
            <p>
              I am a final semester student of BIM, currently self learning
              Frontend Development and my preferred stack is MERN. I have worked
              on my college project based on PHP and have basic concepts about
              web development using PHP. I also have some basic core knowledge
              about Java, and my next step will be to focus learning and working
              in MERN Stack. Quality Assurance is another subject that intrigues
              me and am knowledged about Manual Testing techniques and
              Automation Testing would be my next target to learn about.
            </p>
          </div>
        </div>
      </div>
      <img
        src={arrowIcon}
        alt="Arrow"
        className="icon arrow"
        onClick={() => (window.location.href = "/#experience")}
      />
    </section>
  );
};

export default About;
