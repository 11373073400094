import React from "react";
import { useNavigate } from "react-router-dom";
import ivyProject from "../../assets/ivy.png";
import hwInfoProject from "../../assets/HWInfo.png";
import tictactoeProject from "../../assets/tictactoe.jpg";
import arrowIcon from "../../assets/arrow.png";

const Projects = () => {
  const navigate = useNavigate();

  return (
    <section id="projects">
      <p className="section__text__p1">Browse My Recent</p>
      <h1 className="title">Projects</h1>
      <div className="experience-details-container">
        <div className="about-containers">
          <div className="details-container color-container">
            <div className="article-container">
              <img
                src={ivyProject}
                alt="Futsal Management System"
                className="project-img"
              />
            </div>
            <h2 className="experience-sub-title project-title">
              Futsal Management System
            </h2>
            <div className="btn-container">
              <button
                className="btn btn-color-2 project-btn"
                onClick={() =>
                  (window.location.href = "https://github.com/extremex7")
                }
              >
                Github
              </button>
            </div>
          </div>

          <div className="details-container color-container">
            <div className="article-container">
              <img
                src={hwInfoProject}
                alt="Hardware Info App"
                className="project-img"
              />
            </div>
            <h2 className="experience-sub-title project-title">
              Hardware Info React Native App
            </h2>
            <div className="btn-container">
              <button
                className="btn btn-color-2 project-btn"
                onClick={() =>
                  (window.location.href =
                    "https://github.com/extremex7/MobileInformationApp")
                }
              >
                Github
              </button>
            </div>
          </div>

          <div className="details-container color-container">
            <div className="article-container">
              <img
                src={tictactoeProject}
                alt="Tic Tac Toe Game"
                className="project-img"
              />
            </div>
            <h2 className="experience-sub-title project-title">Tic-Tac-Toe</h2>
            <div className="btn-container">
              <button
                className="btn btn-color-2 project-btn"
                onClick={() =>
                  (window.location.href = "https://github.com/extremex7")
                }
              >
                Github
              </button>
              <button
                className="btn btn-color-2 project-btn"
                onClick={() => navigate("/tictactoe")}
              >
                Live Demo
              </button>
            </div>
          </div>
        </div>
      </div>
      <img
        src={arrowIcon}
        alt="Arrow"
        className="icon arrow"
        onClick={() => (window.location.href = "/#contact")}
      />
    </section>
  );
};

export default Projects;
