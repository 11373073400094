import React from "react";
import checkmarkIcon from "../../assets/checkmark.png";
import arrowIcon from "../../assets/arrow.png";

const Experience = () => {
  return (
    <section id="experience">
      <p className="section__text__p1">Explore My</p>
      <h1 className="title">Experience</h1>
      <div className="experience-details-container">
        <div className="about-containers">
          <div className="details-container">
            <h2 className="experience-sub-title">Frontend Development</h2>
            <div className="article-container">
              {["HTML", "CSS", "JavaScript", "React"].map((skill) => (
                <article key={skill}>
                  <img src={checkmarkIcon} alt="Checkmark" className="icon" />
                  <div>
                    <h3>{skill}</h3>
                    <p>{skill === "React" ? "Basic" : "Intermediate"}</p>
                  </div>
                </article>
              ))}
            </div>
          </div>

          <div className="details-container">
            <h2 className="experience-sub-title">Backend Development</h2>
            <div className="article-container">
              {[
                { name: "Java", level: "Basic" },
                { name: "Node", level: "Basic" },
              ].map((skill) => (
                <article key={skill.name}>
                  <img src={checkmarkIcon} alt="Checkmark" className="icon" />
                  <div>
                    <h3>{skill.name}</h3>
                    <p>{skill.level}</p>
                  </div>
                </article>
              ))}
            </div>
          </div>

          <div className="details-container">
            <h2 className="experience-sub-title">Others</h2>
            <div className="article-container">
              {[
                { name: "MySQL", level: "Basic" },
                { name: "Quality Assurance", level: "Basic" },
                { name: "Git", level: "Intermediate" },
              ].map((skill) => (
                <article key={skill.name}>
                  <img src={checkmarkIcon} alt="Checkmark" className="icon" />
                  <div>
                    <h3>{skill.name}</h3>
                    <p>{skill.level}</p>
                  </div>
                </article>
              ))}
            </div>
          </div>
        </div>
      </div>
      <img
        src={arrowIcon}
        alt="Arrow"
        className="icon arrow"
        onClick={() => (window.location.href = "/#projects")}
      />
    </section>
  );
};

export default Experience;
